import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'

interface BenefitsCarouselProps {
  BenefitsProps?: Array<{
    image?: string
    title?: string
    alt?: string
    description?: string
    reminder?: string
    link?: string
    widthIcon?: string
    widthMobIcon?: string
    maxTextWidth?: string
    reminderWidth?: string
  }>
}

const BenefitsCarousel = ({ BenefitsProps }: BenefitsCarouselProps) => {
  return (
    <Swiper
      className="swiper-benefits-natal"
      navigation
      spaceBetween={24}
      breakpoints={{
        1440: {
          slidesPerView: 4,
        },
        1244: {
          slidesPerView: 4,
        },
        1100: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        425: {
          slidesPerView: 2,
        },
        360: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 2,
        },
      }}
      lazy={{
        loadPrevNext: true,
        loadPrevNextAmount: 1,
      }}
      modules={[Navigation, Lazy]}
    >
      {BenefitsProps?.map((benefit, index) => {
        return (
          <SwiperSlide key={index}>
            {benefit.link ? (
              <div className="benefits-content">
                <a href={benefit.link}>
                  <img
                    id="desk"
                    src={benefit.image}
                    alt={`${benefit} | Decathlon`}
                    style={{ maxWidth: benefit.widthIcon }}
                  />
                  <img
                    id="mob"
                    src={benefit.image}
                    alt={`${benefit.alt} | Decathlon`}
                    style={{ maxWidth: benefit.widthMobIcon }}
                  />
                  <h2>{benefit.title}</h2>
                  <h3 style={{ maxWidth: benefit.maxTextWidth }}>
                    {benefit.description}
                  </h3>
                  <p style={{ maxWidth: benefit.reminderWidth }}>
                    {benefit.reminder}
                  </p>
                </a>
              </div>
            ) : (
              <div className="benefits-content">
                <img
                  id="desk"
                  src={benefit.image}
                  alt={`${benefit} | Decathlon`}
                  style={{ maxWidth: benefit.widthIcon }}
                />
                <img
                  id="mob"
                  src={benefit.image}
                  alt={`${benefit} | Decathlon`}
                  style={{ maxWidth: benefit.widthMobIcon }}
                />
                <h2>{benefit.title}</h2>
                <h3 style={{ maxWidth: benefit.maxTextWidth }}>
                  {benefit.description}
                </h3>
                <p style={{ maxWidth: benefit.reminderWidth }}>
                  {benefit.reminder}
                </p>
              </div>
            )}
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default BenefitsCarousel
